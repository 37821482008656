<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            class="elevation-1"
            @current-items="currentItems"
            :loading="loading"
            loading-text="Loading... Please wait"
            @click:row="goToItemPage"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Work Orders List</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-text-field
                  v-if="search_tool"
                  v-model="search"
                  ref="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>

                <!-- BOM Filtros -->
                <v-dialog
                  v-if="search_tool"
                  v-model="dialogFilters"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ml-4"
                      color="blue-grey"
                      fab
                      small
                      dark
                      v-on="on"
                      @click="dialogFilters = true"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Advanced Search</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-menu
                              ref="rangeCalendar"
                              v-model="rangeCalendar"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-x
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="dateRangeText"
                                  label="Dates"
                                  hint="Enter a date range range. Separate with '~'"
                                  persistent-hint
                                  prepend-icon="event"
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="rangeDates"
                                no-title
                                range
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="rangeDates = []"
                                  >Clear</v-btn
                                >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.rangeCalendar.save(rangeDates)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <!-- FILTER -->
                            <v-autocomplete
                              placeholder="Select Handler"
                              :items="handlerList"
                              item-text="name"
                              item-value="id"
                              label="Handler"
                              v-model="filterHandler"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="filterWorkOrder"
                              label="Work Order"
                              single-line
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="filterVessel"
                              label="Vessel"
                              single-line
                              hide-details
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-autocomplete
                              :items="['All', 'Yes', 'No']"
                              label="Closed"
                              v-model="filterClosed"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="clearFilterItems()"
                        >Clear Filters</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogFilters = false"
                        >Close</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="filterItems()"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- EOM Filtros -->

                <v-spacer></v-spacer>

                <v-btn color="primary" dark class="mb-2" @click="newItem()"
                  >New Work Order</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:item.work_order="{ item }">
              {{ item.id }}
            </template>
            <template v-slot:item.closed="{ item }">
              <v-chip color="green" dark v-if="item.closed == '1'">
                yes
              </v-chip>
              <v-chip color="red" dark v-else> no </v-chip>
            </template>
            <template
              v-if="this.currentUser.role != 'Surveyor'"
              v-slot:item.actions="{ item }"
            >
              <td @click.stop class="non-clickable">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item, 'dialog')"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  mdi-pencil
                </v-icon>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length + 1">
                <div class="row justify-center">
                  <div class="col-md-11">
                    <div class="row mt-3">
                      <div class="col-md-3">
                        <p>
                          <b>Notice Date:</b><br />
                          {{ item.notice_date }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Port:</b><br />
                          {{ item.port_label }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Agent:</b><br />
                          {{ item.agent }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Underwritter:</b><br />
                          {{ item.undewritters }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p>
                          <b>Description:</b><br />
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </template>
            <template slot="footer">
              <table>
                <tr
                  class="border border-right-0 border-left-0 border-bottom-0 text-center"
                >
                  <td colspan="">
                    <v-chip color="lighten-1 mr-4">
                      Items: &nbsp;
                      <strong class="ml-2">{{ itemsFiltered }}</strong>
                    </v-chip>
                  </td>
                </tr>
              </table>
            </template>
          </v-data-table>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    uri: "workorders",
    search_tool: true,
    activateFocus: false,
    dialog: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    headers: [
      { text: "Work Order", value: "id", sortable: true, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: true },
      {
        text: "Handler",
        align: "start",
        value: "handler",
        sortable: false,
        filterable: true,
      },
      { text: "Country", value: "country", sortable: false, filterable: true },
      { text: "Client", value: "client", sortable: false, filterable: true },
      { text: "Underwritter", value: "undewritters", sortable: false },
      { text: "Closed", value: "closed", sortable: false, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      description: "",
      work_order: 0,
      handler: "",
      date: "",
      ship_name: "",
      country: "",
      port_label: "",
      client: "",
      closed: false,
    },
    defaultItem: {
      description: "",
      work_order: 0,
      handler: "",
      date: "",
      ship_name: "",
      country: "",
      port_label: "",
      clients: "",
      closed: false,
    },
    switch1: true,
    switch2: false,
    switch3: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Work Order", value: 0 },
      { text: "Vessel", value: 1 },
      { text: "Description", value: 3 },
      { text: "Handler", value: 4 },
      { text: "Country", value: 5 },
      { text: "Clients", value: 6 },
    ],
    radios: "",
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    filteredItemList: [],
    filterWorkOrder: "",
    filterVessel: "",
    filterClosed: "",
    filterHandler: "",
    handlerList: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Work Order" : "Edit Work Order";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters(["currentUser"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
    // Letter Function
  },

  methods: {
    initialize() {
      var _self = this;

      genericService.getRecordListWithLimit(
        "/" + this.uri,
        100,
        "id",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.items = records;
        }
      );  
    },

    newItem() {
      this.$router.push("/work-order-new");
    },

    editItem(item, dialog) {
      /* Listado de Dialogs */
      if (dialog == "dialog") {
        this.dialog = true;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$router.push("/work-orders/" + item.id);
    },

    deleteItem(item, list) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: "Deleted!",
            text: "Your record has been deleted.",
            icon: "success",
          });
        }
      });
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (
        this.editedItem.vessel == "" ||
        this.editedItem.handler == "" ||
        this.editedItem.date == "" ||
        this.editedItem.description == ""
      ) {
        Swal.fire({
          title: "",
          text: "Fields Vessel, Handler, Date and Description are mandatories.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.contacts[this.editedIndex], this.editedItem);
        genericService.updateRecord(
          "/" + this.uri + "/" + this.editedItem.id,
          this.editedItem,
          function () {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        );
      } else {
        this.contacts.push(this.editedItem);
        genericService.createRecord(
          "/" + this.uri,
          this.editedItem,
          function () {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                location.reload();
              }
            });
          }
        );
      }
      this.close();
    },

    goToItemPage(item) {
      this.$router.push("/work-orders/" + item.id);
      /*const router = this.$router;
      const routeData = router.resolve({name: '/work-orders/' + item.id, query: {}});
      window.open('#' + routeData.location.name, '_blank');*/
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },

    /* BOM - Filtros */
    clearFilterItems() {
      var _self = this;

      _self.rangeDates = [];
      _self.dateRangeText = "";
      _self.filterWorkOrder = "";
      _self.filterVessel = "";
      _self.filterClosed = "";
      _self.filterHandler = "";

      genericService.getRecordListWithLimit(
        "/" + this.uri,
        100,
        "id",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.items = records;
          _self.dialogFilters = false;
        }
      ); 

    },

    filterItems() {
      var _self = this;
      
      var params = {
        module: _self.uri,
        range: _self.rangeDates,
        wo: _self.filterWorkOrder,
        handler: _self.filterHandler,
        vessel: _self.filterVessel,
        closed: _self.filterClosed,
      };

      genericService.getRecordListWithParams(params, _self.currentUser.role, _self.currentUser.id, function (records) {
        _self.items = records;
        _self.dialogFilters = false;
      });
      
    },

    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },

    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    /* EOM - Filtros */
  },
  components: {},
  mounted() {
    var _self = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Work Orders" }]);

    if (this.$route.params.id == "new") {
      setTimeout(() => {
        this.dialog = true;
      }, 700);
    } else {
      if (this.search_tool == true) {
        this.focusField(this.$refs.search);
      }
    }

    genericService.getRecordList(
      "/list/users/" + this.currentUser.role + "/handler",
      function (records) {
        _self.handlerList = records;

        if (_self.currentUser.role == "Handler") {
          _self.blockHanlderList = false;
        }
      }
    );
  },
};
</script>
<style lang="scss" scoped>
::v-deep tbody tr {
  cursor: pointer;
}
::v-deep tbody tr td.non-clickable {
  cursor: auto;
}
</style>
